<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <div class="py-30 flex justify-center">
      <div v-html="info"></div>
    </div>
  </div>
</template>

<script>
import req from "../../utils/req";
export default {
  data() {
    return {
      info: "",
      moveid: "",
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        let moveid = this.$route.query.moveid;
        if (moveid) {
          this.moveid = moveid;
          this.getdata();
        }
      },
      deep: true,
    },
  },
  mounted() {
    let moveid = this.$route.query.moveid;
    if (moveid) {
      this.moveid = moveid;
      this.getdata();
    }
  },
  methods: {
    // 获取数据
    getdata() {
      let that = this;
      req
        .post("index/comicInfo", {
          id: this.moveid,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          res.data.content = res.data.content
            .replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block" ')
            .replace(/<section/g, "<div")
            .replace(/\/section>/g, "div>");
          that.info = res.data.content;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.purseBox {
  height: 100%;
  box-sizing: border-box;
}
</style>
